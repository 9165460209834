.custom {
  padding: rem(0 0 80px);
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: rem(0 0 50px);
  }
  &__inner {
    letter-spacing: rem(.2px);
    font-size: rem(18px);
    line-height: calc(28 / 18);
    h2 {
      @extend .ttu;
      color:cl(black);
      font-size: rem(30px);
      font-weight: 700;
      margin: rem(0 0 10px);
    }
  }
  &__list {
    counter-reset: item;
    padding: 0!important;
  }
  &__item {
    position: relative;
    overflow: hidden;
    padding: rem(30px 0 0 80px);
    &:before {
      content: counter(item);
      counter-increment: item;
      text-align: center;
      position: absolute;
      left:0;
      top:0;
      width:rem(50px);
      background: cl(white) url(../img/arrow-down.png) no-repeat 50% 73%;
      background-size: 100% auto;
      color:cl(primary);
      font-size: rem(60px);
      line-height: 1;
      padding: rem(15px 0 25px);
      font-weight: 700;
      z-index: 2;
    }
    &:after {
      content: "";
      height: 100%;
      width:2px;
      background: #606060;
      position: absolute;
      left:rem(24px);
      top:0;
    }
    h2 {
      font-size: rem(32px)!important;
      text-transform: none!important;
    }
    p {

    }
    ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
  }
}