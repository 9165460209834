.bullet {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(farthest-corner at 50% 30%, $gradientFirst 0, $gradientSecond 100%);
  &--sm {
    width:rem(54px);
    height: rem(54px);
  }
  &--md {
    width:rem(80px);
    height: rem(80px);
  }
  &--lg {
    width:rem(113px);
    height: rem(113px);
  }
  &--xl {
    width:rem(150px);
    height: rem(150px);
  }
}