.about {
  &--text {
    text-align: center;
    font-size: rem(20px);
    line-height: calc(32 / 20);
    position: relative;
    z-index: 3;
    h2 {
      @extend .ttu;
      color:cl(black);
      font-size: rem(40px);
      font-weight: 700;
      margin: rem(0 0 15px);
    }
    .btn {
      width:rem(212px);
      margin: rem(35px auto -25px);
    }
  }
  &--dark {
    padding: rem(100px 210px 215px 100px);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color:cl(white);
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      padding: rem(70px 50px 150px 50px);
    }
    @include media-breakpoint-down(md) {
      padding: rem(70px 16px 250px 16px);
      margin: 0 0 -3rem;
    }
  }
  &__video {
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }
    video {
      display: block;
      width:100%;
      height: auto;
      box-shadow: rem(0 20px 50px cl(black,.34));
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    font-size: rem(18px);
    line-height: calc(28 / 18);
    position: relative;
    z-index: 2;
    p {
      margin: rem(0 0 25px);
    }
    ul {
      margin: rem(0 0 25px);
      li {
        position: relative;
        list-style: none;
        padding: rem(0 0 0 20px);
        margin: rem(0 0 10px);
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(11px);
          width:rem(4px);
          height: rem(4px);
          border-radius: 50%;
          background: cl(primary);
        }
      }
    }
  }
  &__logo {
    position: absolute;
    height: 88%;
    width:auto;
    right:12%;
    top:0;
    opacity: .03;
    @include media-breakpoint-down(xs) {
      top: auto;
      bottom: 9.625rem;
      height: 14rem;
      right: 0;
    }
  }
  &__bullet {
    @extend .full;
    z-index: 1;
    opacity: .6;
    .bullet {
      &:nth-child(1){
        right: 6%;
        bottom: 20%;
      }
      &:nth-child(2) {
        right: 5%;
        bottom: 35%;
      }
      &:nth-child(3){
        left: 2%;
        bottom: 13%;
      }
    }
  }
}