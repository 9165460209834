.static {
  &--page {
    padding: rem(20px 0 60px 0);
  }
  &__article {
    font-size: rem(18px);
    line-height: calc(28 / 18);
    color:cl(text);
    width:100%;
    p {
      margin: rem(0 0 20px);
    }
    h2 {
      font-size: rem(40px);
      text-transform: uppercase;
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h3 {
      font-size: rem(30px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h4 {
      font-size: rem(28px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h5 {
      font-size: rem(26px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h6 {
      font-size: rem(24px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    ol, ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:90%;
      height: auto;
    }
  }
  &__wrap {
    display: flex;
    gap:rem(40px);
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    gap:1rem;
    padding: rem(20px 0 0);
    @include media-breakpoint-down(xs) {
      flex-direction: column-reverse;
    }
  }
  .about {
    max-width: rem(700px);
    margin: 0 auto;
    color:cl(white);
    &__item {
      font-size: rem(24px);
      border-bottom: 1px solid cl(white);
      padding: rem(10px 35px);
      display: flex;
      align-items: center;
      line-height: 1.2;
      background: #292929;
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
      strong {
        color:cl(primary);
        font-size: rem(53px);
        width:rem(55px);
        flex-shrink: 0;
        line-height: 1;
      }
    }
  }
}