.footer {
  padding: rem(20px 0);
  color:cl(dark);
  font-size: rem(18px);
  line-height: calc(30 / 18);
  @include media-breakpoint-down(xs) {
    text-align: center;
    margin-bottom: rem(55px);
  }
  &__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(40px);
      color:cl(white);
      margin-left: rem(10px);
      border:rem(2px solid cl(white));
      &:hover {
        text-decoration: none;
        color:cl(primary);
        border-color:cl(primary);
      }
      i {
        font-size: rem(18px);
      }
    }
  }
  &__logo {
    display: block;
    width:100%;
    &--site {
      max-width:rem(154px);
    }
    &--accreditation {
      max-width:rem(90px);
      @include media-breakpoint-down(xs) {
        max-width:rem(60px);
      }
    }
    &--foundation {
      max-width:rem(155px);
      @include media-breakpoint-down(xs) {
        max-width:rem(100px);
      }
    }
    @include media-breakpoint-down(xs) {
      margin: 0 auto;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  &__address {
    margin: 0;
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
    p {
      margin: 0;
    }
    a {
      color:cl(dark);
      &:hover {
        text-decoration: none;
        color:cl(primary);
      }
    }
  }
  &__copy {
    display: block;
    font-size: rem(14px);
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
  }
  &__supported {
    display: block;
    font-size: rem(14px);
    text-align: center;
    margin-top: rem(15px);
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
    img {
      vertical-align: middle;
    }
  }
}

