.staff {
  &__title {
    margin: rem(0 0 32px);
    h2 {
      color:cl(black);
      text-transform: uppercase;
      text-align: center;
      font-size: rem(30px);
      font-weight: 700;
      font-family: $font;
      margin: 0;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    --gap-side: 3.125rem;
    gap:1.875rem var(--gap-side);
    list-style: none;
    margin: rem(0 0 50px);
    @include media-breakpoint-down(xs) {
      --gap-side: 1.875rem;
    }
    &--sm {
      .staff__item {
        @include media-breakpoint-up(xl) {
          --cols: 4;
        }
      }
    }
  }
  &__item {
    text-align: center;
    font-family: $font;
    line-height: 1.2;
    --cols: 5;
    flex-basis: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    padding: 0!important;
    &:before {
      opacity: 0;
    }
    @include media-breakpoint-down(lg) {
      --cols: 4;
    }
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    h3 {
      color:cl(black);
      font-size: rem(18px);
      font-family: $font;
      margin: rem(0 0 5px);
      a {
        color:cl(black);
        &:hover {
          text-decoration: none;
          color:cl(primary);
        }
      }
    }
    p {
      margin: 0;
    }
  }
  &__card {
    text-align: center;
    line-height: 1.2;
    .staff__photo {
      width:rem(250px);
      height: rem(230px);
      margin: 0 auto 1.2rem;
      box-shadow: rem(0 10px 20px cl(black,.18));
    }
    h2 {
      color:cl(black);
      font-size: rem(26px);
      font-family: $font;
      margin: rem(0 0 20px);
    }
  }
  &__photo {
    display: block;
    height: rem(180px);
    overflow: hidden;
    margin: rem(0 0 15px);
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
}