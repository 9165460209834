.news {
  &--page {
    padding: rem(20px 0 60px 0);
  }
  &__slider {
    padding: rem(0 50px);
    @include media-breakpoint-down(xs) {
      padding: 0 2.5rem;
    }
    .news__item {
      margin: rem(5px 20px 35px);
      box-shadow: rem(0 15px 20px cl(black,.18));
    }
  }
  &__prev,
  &__next {
    @include circle(3.125rem);
    border:2px solid cl(primary);
    background: none;
    color:cl(primary);
    position: absolute;
    top:rem(180px);
    left:0;
    &:hover,
    &:focus {
      outline: none;
      border-color:cl(black);
      color:cl(black);
    }
    @include media-breakpoint-down(xs) {
      @include circle(2.5rem);
    }
  }
  &__next {
    left: auto;
    right:0;
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    --gap: 2rem;
    gap:2rem var(--gap);
    .news__item {
      --cols: 4;
      flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
      @include media-breakpoint-down(md) {
        --cols: 3 ;
      }
      @include media-breakpoint-down(xs) {
        --cols: 1;
      }
      h3 {
        @include media-breakpoint-down(xs) {
          height: auto!important;
        }
      }
    }
  }
  &__item {
    background: cl(white);
    box-shadow: rem(0 15px 20px cl(black,.18));
  }
  &__image {
    display: block;
    overflow: hidden;
    height: rem(200px);
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    @include media-breakpoint-down(xs) {
      height: 12rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s linear;
    }
  }
  &__inner {
    padding: rem(20px 30px);
    font-size: rem(16px);
    line-height: rem(24px);
    color:currentColor;
    @include media-breakpoint-up(xl) {
      padding-left: rem(20px);
    }
    h3 {
      font-size: rem(20px);
      line-height: rem(30px);
      font-weight: 700;
      color:cl(black);
      margin: rem(0 0 20px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      height: rem(90px);
      a {
        color:cl(black);
        &:hover {
          text-decoration: none;
          color:cl(primary);
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      margin: 0;
      height: rem(120px);
    }
  }
  &__more {
    font-size: rem(15px);
    display: inline-block;
    vertical-align: top;
    margin-top: rem(5px);
    font-weight: 600;
  }
}