.album {
  &--page {
    padding: rem(20px 0 60px 0);
    overflow: hidden;
  }
  &__title {
    font-size: rem(34px);
    text-transform: uppercase;
    color:cl(black);
    margin: rem(0 0 20px);
    font-weight: 700;
    @include media-breakpoint-down(xs) {
      font-size: rem(28px);
    }
  }
  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    --gap: 1.5rem;
    gap:var(--gap);
  }
  &__item {
    --cols: 4;
    flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(md) {
      --cols: 3;
    }
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    h2 {
      font-size: rem(18px);
      letter-spacing: rem(0.2px);
      margin: rem(18px 0 0);
      font-weight: 700;
      color:cl(black);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      a {
        color:cl(black);
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
  }
  &__image {
    display: block;
    overflow: hidden;
    height: rem(210px);
    position: relative;
    box-shadow: rem(0 10px 20px cl(black,.18));
    @include media-breakpoint-down(xs) {
      height: 8rem;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      .album-play {
        opacity: 1;
      }
    }
    img {
      display: block;
      object-fit: cover;
      width:100%;
      height: 100%;
      transition: all .2s linear;
    }
  }
  &__count {
    position: absolute;
    top:rem(8px);
    right: 0;
    background:cl(primary, .92);
    padding: rem(5px 10px);
    color:cl(white);
    font-size: rem(14px);
  }
  &__play {
    @extend .pos-center;
    color:cl(white);
    font-size: rem(34px);
    transition: all .2s linear;
    opacity: .8;
  }
  &__date {
    display: block;
    font-size: rem(14px);
    color:cl(gray-derk);
    margin-top: rem(5px);
  }
}