.contact {
  &--bottom {
    padding: rem(110px 0 150px);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    color:cl(white);
    font-size: rem(18px);
    line-height: calc(28 / 18);
    margin: rem(0 0 -35px);
    @include media-breakpoint-down(xs) {
      padding-top: 3rem;
      margin: 0 0 -4.1875rem;
    }
    .container {
      position: relative;
      z-index: 3;
    }
    h3 {
      @extend .ttu;
      font-size: rem(50px);
      line-height: calc(60 / 50);
      font-weight: 700;
      margin: rem(0 0 25px);
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
      }
    }
    h4 {
      font-size: rem(30px);
      line-height: calc(50 / 30);
      font-weight: 700;
      margin: rem(0 0 25px);
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
    }
  }
  &__logo {
    position: absolute;
    height: 84%;
    width:auto;
    right:15%;
    top:0;
    opacity: .13;
    @include media-breakpoint-down(xs) {
      width:90%;
      right:5%;
      height: auto;
    }
  }
  &__bullet {
    @extend .full;
    z-index: 1;
    opacity: .8;
    .bullet {
      &:nth-child(1){
        left: 5%;
        top: 20%;
      }
      &:nth-child(2) {
        right: 3%;
        top: 10%;
      }
      &:nth-child(3) {
        right: 5%;
        top: 35%;
      }
    }
  }
}