.sidebar {
  width:rem(280px);
  flex-shrink: 0;
  @include media-breakpoint-down(sm) {
    width:100%;
  }
  &__open {
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem(18px);
    line-height: rem(58px);
    border-radius: rem(30px);
    background: cl(primary);
    color:cl(white);
    &:before {
      content: "Open Menu";
      display: block;
    }
    &--active {
      &:before {
        content: "Hide Menu";
        display: block;
      }
      span {
        transform: rotate(180deg);
      }
    }
    span {
      transition: all .2s linear;
    }
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &__staff {
    padding: rem(0 20px 20px);
    background: cl(gray-bg);
    font-family: $font;
    text-align: center;
    img {
      display: block;
      width:100%;
      height: auto;
      position: relative;
      top: -20px;
    }
    h3 {
      color:cl(primary);
      font-size: rem(22px);
      font-family: $font;
      margin: rem(0 0 5px);
    }
    p {
      margin: 0;
      color:#8c8c8c;
    }
  }
  &__menu {
    list-style: none;
    background: cl(white);
    font-size: rem(18px);
    font-family: $font;
    box-shadow:rem(0 14px 32px cl(black,.21));
    padding: rem(15px 0);
    margin: 0;
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
    li {
      margin-bottom: 1px;
    }
    a {
      display: block;
      color:cl(text);
      padding: rem(12px 10px 12px 30px);
      text-decoration: none;
      &:hover,
      &:focus,
      &.current{
        background: cl(primary);
        color:cl(white);
      }
    }
  }
}