.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	background: cl(black);
	&__wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: rem(130px);
		@include media-breakpoint-down(lg){
			//padding: 0 2rem;
		}
		@include media-breakpoint-down(sm) {
			//padding: 0 1rem 0 1.5rem;
		}
		@include media-breakpoint-down(xs) {
			height: 6.5rem;
		}
	}
	&__logo {
		width:rem(154px);
		flex-shrink: 0;
		@include media-breakpoint-down(xs) {
			width:7.5rem;
		}
		img {
			max-width: 100%;
			height: auto;
		}
		&--nav {
			display: none;
			width:7.5rem;
			height: auto;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
	}
	&__slogan {
		position: absolute;
		top:rem(5px);
		left:0;
		width:100%;
		color:cl(white);
		font-size: rem(14px);
		padding: rem(0 0 0 160px);
		letter-spacing: rem(.3px);
		white-space: nowrap;
		text-align: center;
		font-weight: normal;
		z-index: 2;
		@include media-breakpoint-down(xs) {
			padding: 0 0 0 8rem;
		}
		strong {
			display: block;
			width:100%;
			font-weight: 500;
			overflow: hidden;
		}
		span {
			//padding:0 0 0 100%;
			display:inline-block;
			animation: marquee 15s infinite linear;
		}
	}
	&__inner {
		padding: rem(20px 0 0);
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: rem(8px);
		width:100%;
		position: relative;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
		@include media-breakpoint-down(xs) {
			padding: 0;
		}
	}
	&__top {
		display: flex;
		align-items: center;
		gap: rem(20px);
		@include media-breakpoint-down(md) {
			gap: rem(10px);
			flex-wrap: wrap;
			justify-content: flex-end;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	&__lang {
		@include media-breakpoint-down(xs) {
			margin-right: rem(20px);
		}
	}
	&__nav {
		display: flex;
		justify-content: center;
		align-items: center;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: 7rem 0 0;
			background: cl(black);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		& > ul {
			display: flex;
			align-items: center;
			gap: rem(16px);
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.1));
					}
				}
				& > a {
					display: block;
					font-size: rem(15.5px);
					line-height: rem(17px);
					letter-spacing: rem(.2px);
					padding: rem(7px 0);
					font-weight: 700;
					text-transform: uppercase;
					color:cl(white);
					position: relative;
					text-align: center;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
					}
					@include media-breakpoint-up(xl) {
						&:before {
							content:"";
							position: absolute;
							left:0;
							bottom: 0;
							width:0;
							height: rem(2px);
							background: cl(white);
							transition: all .3s ease;
						}
					}
					&:hover {
						text-decoration: none;
						color:cl(primary);

						@include media-breakpoint-up(xl) {
							&:before {
								width:100%;
							}
						}
					}
					&.with-drop:after {
						@include media-breakpoint-down(lg) {
							content: '\f107';
							position: absolute;
							top:1.5rem;
							right: 1rem;
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							transition: all .2s linear;
						}
					}
					&.with-drop.opened {
						background: cl(white,.2);
						&:after {
							transform: rotate(180deg);
						}
					}
				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(primary);
						@include media-breakpoint-up(xl) {
							&:before {
								width:100%;
							}
						}
					}
				}
				&:hover {
					& > .drop {
						@include media-breakpoint-up(xl) {
							display: block;
						}
					}
				}
				.drop {
					position: absolute;
					top:calc(100% - 1px);
					// padding-top: rem(21px);
					left:0;
					width:rem(250px);
					display: none;
					@include media-breakpoint-down(lg) {
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(black);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
							background: cl(white, .1);
						}
						li {
							a {
								display: block;
								font-size: rem(15px);
								line-height: rem(18px);
								color:cl(white);
								padding: rem(10px 10px 10px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(white, .1));
								}
								&:hover {
									text-decoration: none;
									background: cl(primary);
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(primary);
								}
							}
						}
					}
				}
			}
		}
	}
	&__buttons {
		position: relative;
		display: flex;
		gap:rem(20px);
		align-items: center;
		@include media-breakpoint-down(xs) {
			padding: 1rem;
			justify-content: space-between;
			justify-content: center;
			gap:rem(10px);
			width:100%;
			flex-wrap: wrap;
		}
		.btn {
			line-height: rem(40px);
			@include media-breakpoint-down(xs) {
				width:48%;
			}
		}
	}
	&__open-nav {
		display: none;
		position: relative;
		padding: 0;
		height:rem(20px);
		width:rem(26px);
		flex-shrink: 0;
		text-indent: -9999px;
		appearance: none;
		border: none;
		cursor: pointer;
		background: none;
		border:0;
		z-index: 200;
		@include media-breakpoint-up(sm) {
			margin: rem(0 0 0 32px);
		}
		&:focus {
			outline: none;
		}
		@include media-breakpoint-down(lg){
			display: block;
		}
		span,
		span::before,
		span::after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height:rem(2px);
			background-color: cl(primary);
			content: "";
			transition: transform 0.3s ease;
		}
		span {
			top:rem(9px);
			&:before {
				top:rem(-7px);
			}
			&:after{
				bottom:rem(-7px);
			}
		}
		&--active {
			&:before {
				display: none;
			}
			span {
				background: none;
				&:before {
					top: 0;
					transform:rotate(45deg);
					background: cl(white);
				}
				&:after{
					bottom: 0;
					transform:rotate(-45deg);
					background: cl(white);
				}
			}
		}
	}
}


.translated-ltr,
.translated-rtl {
	.header {
		margin-top: rem(39px);
		.header-inner {
			padding-top: 2.875rem;
		}
		.header-close_nav {
			top: 3.75rem;
		}
	}
}

.goog-te-gadget-simple {
	color:cl(white)!important;
	background: transparent!important;
	border:0!important;
	.goog-te-menu-value {
		color:cl(white)!important;
		font-family: $font;
		span {
			color:cl(white)!important;
		}
	}
	.goog-te-gadget-icon {
		display: none!important;
	}
	span {
		color:cl(white)!important;
	}
}