/* banner start */
.banner {
  position: relative;
  background: cl(primart);
  margin: rem(130px 0 0);
  @include media-breakpoint-down(xs) {
    margin: 6.5rem 0 0;
  }
  &--home {
    padding: rem(70px 210px 165px);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      //height: 60rem;
      padding: rem(50px 50px 165px);
    }
    @include media-breakpoint-down(sm) {
      //height: 31rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 3rem 1rem 10rem;
    }
  }
  &--page {
    height: rem(440px);
    margin-bottom: rem(50px);
    box-shadow:rem(6px 20px 32px cl(black,.33));
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(xs) {
      height: 20rem;
    }
    .container {
      position: relative;
      z-index: 3;
    }
    &:before {
      content: '';
      @extend .full;
      background: cl(black, 0.2);
      z-index: 1;
    }
  }
  &__decor {
    position: absolute;
    right:0;
    //top: 100%;
    bottom: 0;
    width:rem(969px);
    height: auto;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width:100%;
    }
  }
  &__title {
    @extend .full;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    h1 {
      font-size: rem(70px);
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
      @include media-breakpoint-down(xs) {
        font-size: rem(50px);
      }
    }
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: '';
      @extend .full;
      background: cl(black, 0.5);
      z-index: 2;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      //@include media-breakpoint-down(xs) {
      //  margin-left: 0;
      //  top: -20px;
      //  height: calc(100% + 20px);
      //}
      //@media #{$min-aspect169} {
      //  height: 400%;
      //  top: -150%;
      //}
      //@media #{$max-aspect169} {
      //  width: 400%;
      //  left: -150%;
      //}
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    color:cl(white);
    position: relative;
    z-index: 6;
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
    p {
      margin: 0;
    }
  }
  &__ttl {
    display: block;
    font-size: rem(50px);
    line-height: calc(60 / 50);
    letter-spacing: rem(.2px);
    margin: rem(0 0 35px);
    text-transform: uppercase;
    font-weight: 700;
    @include media-breakpoint-down(lg) {
      font-size: rem(40px);
    }
  }
  &__txt {
    display: block;
    font-size: rem(24px);
    line-height: 1.4;
    margin: 0;
    @include media-breakpoint-down(lg) {
      font-size: rem(20px);
    }
  }
  &__slider {

    position: relative;
    z-index: 6;
    @include media-breakpoint-down(xs) {
      margin: rem(20px -50px 0);
    }
  }
  &__slide,
  &__iframe {
    //height: rem(500px);
    padding-bottom: 49.6%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: rem(0 20px 50px cl(black,.34));
    margin: rem(30px 50px 70px);
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__actions {
    margin: rem(-40px 50px 0);
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 200;
    @include media-breakpoint-down(xs) {
      margin: rem(-40px 0 0);
    }
  }
  &__prev,
  &__next {
    @include circle(3.125rem);
    border:2px solid cl(white);
    background: none;
    color:cl(white);
    &:hover,
    &:focus {
      outline: none;
      border-color:cl(black);
      color:cl(black);
    }
  }
  &__logo {
    position: absolute;
    height: 90%;
    width:auto;
    left:10%;
    top:0;
    opacity: .08;
    @include media-breakpoint-down(xs) {
      height: 40%;
    }
  }
  &__bullet {
    @extend .full;
    z-index: 1;
    opacity: .8;
    .bullet {
      &:nth-child(1){
        left:3%;
        bottom: 20%;
      }
      &:nth-child(2) {
        right: 3%;
        top: 6%;
      }
      &:nth-child(3){
        left:2%;
        top: 15%;
      }
      &:nth-child(4){
        right:2%;
        top: 50%;
      }
    }
  }
}
/* banner end */

