.social {
  display: flex;
  gap:rem(17px);
  @include media-breakpoint-down(xs) {
    justify-content: center;
    width:100%;
  }
  &--footer {
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }
  &__link {
    width:rem(42px);
    height: rem(42px);
    border:1px solid cl(primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:cl(white);
    transition: all .3s ease;
    &:hover {
      text-decoration: none;
      background:cl(primary);
      color:cl(white);
    }
    &--footer {
      border-color:cl(black);
      color:cl(black);
      &:hover {
        border-color:cl(primary);
      }
    }
  }
}