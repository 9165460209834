$font: 'Roboto', sans-serif;

$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";


$gradientFirst: #e73b3a;
$gradientSecond: #821d39;

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "text" : "text",
        "gray" : "gray",
        "gray-light" : "gray-light",
        "dark" : "dark",
        "black" : "black",
        "white" : "white"
);

$colors : (
        'primary'  : #de3c35,
        'secondary'  : #ff0000,
        'text'  : #1e263f,
        'gray'  : #aaaaaa,
        'gray-light'  : #f1f1f1,
        'dark'  : #1c1c1c,
        'white' : #FFFFFF,
        'black' : #000000,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color!important;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}