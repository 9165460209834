.line {
  position: relative;
  &__bg {
    display: block;
    width:100%;
    height: auto;
  }
  &__logo {
    position: absolute;
    left:rem(100px);
    top:0;
    height: 100%;
    width:auto;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      left:1rem;
    }
  }
  &__bullet {
    @extend .full;
    z-index: 1;
    opacity: .6;
    .bullet {
      &:nth-child(1){
        left: 27%;
        top: 20%;
      }
      &:nth-child(2) {
        right: 2%;
        top: 25%;
      }
    }
  }
}