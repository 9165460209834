.details {
  padding: rem(0 0 65px);
  overflow: hidden;
  &__image {
    position: relative;
    @include media-breakpoint-down(md) {
      margin: 2rem 0 4rem;
    }
    &:before {
      content: "";
      position: absolute;
      top:rem(-13px);
      height: calc(100% + 1.625rem);
      left:rem(21px);
      width:calc(100% - 2.625rem);
      background: cl(primary);
      box-shadow: rem(0 14px 32px cl(black,.21));
    }
    img {
      display: block;
      width:100%;
      height: auto;
      position: relative;
      z-index: 2;
    }
  }
  &__side {
    box-shadow: rem(0 14px 32px cl(black,.21));
  }
  &__item {
    color:cl(white);
    font-size: rem(18px);
    line-height: rem(28px);
    padding: rem(32px 45px 32px 55px);
    &:nth-child(1) {
      background: cl(primary);
    }
    &:nth-child(2) {
      background: cl(gray-light);
      color:cl(dark);
    }
    &:nth-child(3) {
      background: cl(dark);
    }
    h2 {
      @extend .ttu;
      color:currentColor;
      font-size: rem(30px);
      font-weight: 700;
      margin: rem(0 0 10px);
    }
    p {
      margin: 0;
    }
  }
}