.info {
  padding: rem(0 0 65px);
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: 0 0 3rem;
  }
  h2 {
    @extend .ttu;
    color:cl(primary);
    font-size: rem(35px);
    font-weight: 700;
    margin: rem(0 0 25px);
    @include media-breakpoint-down(mg) {
      text-align: center;
    }
  }
  &__side {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__more {
    padding: rem(0 70px);
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }
  &__events {
    display: flex;
    justify-content: space-around;
    padding: rem(15px 0);
    a {
      text-transform: uppercase;
      color:cl(black);
      font-size: rem(17px);
      padding: rem(0 0 8px);
      font-weight: 700;
      border-bottom: rem(2px solid cl(primary));
      &:hover {
        text-decoration: none;
        color:cl(primary);
      }
    }
  }
}